import { twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

export function StyleCard(props: ParentProps<{ title: string; class?: string }>) {
	return (
		<div
			class={twMerge(
				'col-span-12 flex flex-col flex-wrap gap-4 rounded-md border border-neutral-500 px-8 py-4 md:col-span-6 xl:col-span-4',
				props.class ?? '',
			)}
		>
			<h3 class="font-semibold">{props.title}</h3>
			<div class="flex grow flex-col flex-wrap items-stretch justify-center gap-4">{props.children}</div>
		</div>
	);
}
